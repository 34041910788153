import React from "react"
import { graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"

const StyledGrid = styled.div`
  text-align: center;

  img {
    max-width: 100%;
  }
`

const BlogIndex = ({ data, location }) => {

  return (
    <Layout location={location} title="Newtown Hair">
      <SEO title="Home" />
      <StyledGrid style={{ width: '100vw', }}>
      </StyledGrid>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
